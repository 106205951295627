(function ($) {
	window.sr = ScrollReveal({
		reset: true,
		mobile: true,
	});

	//left(once)
	var effect_left = {
		origin: "left",
		distance: "50px",
		scale: 1,
		rotate: {
			x: 0,
			y: 0,
			z: 0,
		},
		duration: 1200,
		reset: false,
	};
	sr.reveal(".js-sldl", effect_left, 100);

	//left2(once)
	var effect_left2 = {
		origin: "left",
		distance: "50px",
		scale: 1,
		rotate: {
			x: 0,
			y: 0,
			z: 0,
		},
		duration: 1800,
		reset: false,
	};
	sr.reveal(".js-sldl2", effect_left2, 100);

	//right(once)
	var effect_right = {
		origin: "right",
		distance: "50px",
		scale: 1,
		rotate: {
			x: 0,
			y: 0,
			z: 0,
		},
		duration: 1200,
		reset: false,
	};
	sr.reveal(".js-sldr", effect_right, 100);

	//right2(once)
	var effect_right2 = {
		origin: "right",
		distance: "50px",
		scale: 1,
		rotate: {
			x: 0,
			y: 0,
			z: 0,
		},
		duration: 1800,
		reset: false,
	};
	sr.reveal(".js-sldr2", effect_right2, 100);

	//top(once)
	var effect_top = {
		origin: "top",
		distance: "50px",
		scale: 1,
		rotate: {
			x: 0,
			y: 0,
			z: 0,
		},
		duration: 1200,
		reset: false,
	};
	sr.reveal(".js-sldt", effect_top, 100);

	//top2(once)
	var effect_top2 = {
		origin: "top",
		distance: "50px",
		scale: 1,
		rotate: {
			x: 0,
			y: 0,
			z: 0,
		},
		duration: 1800,
		reset: false,
	};
	sr.reveal(".js-sldt2", effect_top2, 100);

	//bottom(once)
	var effect_bottom = {
		origin: "bottom",
		distance: "50px",
		scale: 1,
		rotate: {
			x: 0,
			y: 0,
			z: 0,
		},
		duration: 1200,
		reset: false,
	};
	sr.reveal(".js-sldb", effect_bottom, 100);

	//bottom2(once)
	var effect_bottom2 = {
		origin: "bottom",
		distance: "50px",
		scale: 1,
		rotate: {
			x: 0,
			y: 0,
			z: 0,
		},
		duration: 1800,
		reset: false,
	};
	sr.reveal(".js-sldb2", effect_bottom2, 100);

	//delay(once)
	var effect_delay = {
		delay: 500,
		reset: false,
	};
	sr.reveal(".js-delay", effect_delay);

	//fadein(once)
	var effect_fade = {
		opacity: 0,
		duration: 1000,
		reset: false,
	};
	sr.reveal(".js-fadeIn", effect_fade);
})(jQuery);

(function ($) {
	// Navigation
	$(document).ready(function () {
		var win = $(window),
			header = $("#js-head"),
			headerHeight = $("#js-head").outerHeight(),
			startPos = 0;

		// Navi Fixed
		win.scroll(function () {
			if (win.scrollTop() > 196) {
				header.addClass("fixed");
			} else {
				header.removeClass("fixed");
			}
		});
		win.on("load scroll", function () {
			var value = $(this).scrollTop();
			if (value > startPos && value > headerHeight) {
				header.css("top", "-" + headerHeight + "px");
			} else {
				header.css("top", "0");
			}
			startPos = value;
		});

		// Navi Toggle Button
		var state = false,
			header = $("#js-head"),
			wrap = $("#js-wrap");
		$("#js-nav_btn").click(function () {
			header.toggleClass("open");
			wrap.toggleClass("open");
			if (state == false) {
				scrollpos = $(window).scrollTop();
				$("body").addClass("fixed").css({ top: -scrollpos });
				state = true;
			} else {
				$("body").removeClass("fixed").css({ top: 0 });
				window.scrollTo(0, scrollpos);
				state = false;
			}
		});
	});

	// Navigation click
	var html = $("html"),
		body = $("body"),
		header = $("#js-head"),
		nav = $(".c-nav__global__wrap");

	$("#js-nav a").on("click", function () {
		html.toggleClass("fixed");
		body.toggleClass("fixed");
		header.toggleClass("open");
		nav.toggleClass("open");
	});
	$("#js-nav__contact a").on("click", function () {
		html.toggleClass("fixed");
		body.toggleClass("fixed");
		header.toggleClass("open");
		nav.toggleClass("open");
	});
	$("#js-nav__subnav a").on("click", function () {
		html.toggleClass("fixed");
		body.toggleClass("fixed");
		header.toggleClass("open");
		nav.toggleClass("open");
	});
	$("#js-nav__sns a").on("click", function () {
		html.toggleClass("fixed");
		body.toggleClass("fixed");
		header.toggleClass("open");
		nav.toggleClass("open");
	});
	$("#js-nav__link1 a").on("click", function () {
		html.toggleClass("fixed");
		body.toggleClass("fixed");
		header.toggleClass("open");
		nav.toggleClass("open");
	});
	$("#js-nav__link2 a").on("click", function () {
		html.toggleClass("fixed");
		body.toggleClass("fixed");
		header.toggleClass("open");
		nav.toggleClass("open");
	});
	$("#js-nav__logo a").on("click", function () {
		html.toggleClass("fixed");
		body.toggleClass("fixed");
		header.toggleClass("open");
		nav.toggleClass("open");
	});
	// Current Page
	$("nav li a").each(function () {
		var $href = $(this).attr("href");
		$("nav li.mn-home").removeClass("current");
		if (location.href.match($href)) {
			$(this).parent().addClass("current");
		} else {
			$(this).parent().removeClass("current");
		}
	});

	$('a[href^="#"]').each(function () {
		if (this.href == location.href) {
			$(this).parent().addClass("current");
		} else {
			$(this).parent().removeClass("current");
		}
	});

	// Smooth Scroll
	var headerHeight = $("#js-head").outerHeight(),
		speed = 500,
		urlHash = location.hash;
	if (urlHash) {
		$("body,html").stop().scrollTop(0);
		setTimeout(function () {
			var target = $(urlHash),
				position = target.offset().top - headerHeight;
			$("body,html").stop().animate(
				{
					scrollTop: position,
				},
				speed,
				"swing"
			);
		}, 100);
	}
	$('a[href^="#"]').click(function () {
		var href = $(this).attr("href"),
			target = $(href),
			position = target.offset().top - headerHeight;
		$("body,html").stop().animate(
			{
				scrollTop: position,
			},
			speed,
			"swing"
		);
	});

	// Mouse over Image
	$("a img").hover(
		function () {
			$(this).attr("src", $(this).attr("src").replace("_off", "_on"));
		},
		function () {
			if (!$(this).hasClass("currentPage")) {
				$(this).attr("src", $(this).attr("src").replace("_on", "_off"));
			}
		}
	);

	// disabled Links
	$("a.js-disable").click(function () {
		return false;
	});
})(jQuery);
